import React from 'react'
import { Row, Col } from 'reactstrap';
import {
    BrowserView, 
    IOSView,
    AndroidView
  } from "react-device-detect";


export default function TruckerAppSection() {
    return (
        <section className="section bg-light" id='truckerApp'>
            <div className="container">
                <Row>
                    <Col lg="12">
                        <div className="title-heading mb-5">
                            <h3 className="text-dark mb-1 font-weight-light text-uppercase">PCC Trucker App</h3>
                            <div className="title-border-simple position-relative"></div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" md="7" lg="7">
                        <div className="features-content">
                            
                            <h4 className="font-weight-normal text-dark mb-3 mt-4">Trucker App</h4>
                            
                           

                            <div style={{ alignItems: "center", justifyContent: 'center', textAlign: 'center' , marginBottom: 20 }}>
                               
                                 <AndroidView>
                                    <p className="text-muted f-15" style={{ backgroundColor: 'red', borderRadius: 5 }}>
                                        <span style={{color: '#fff'}}>Descarga el app de Google Play !</span>
                                    </p>
                                    <a href="https://play.google.com/store/apps/details?id=net.cocoasystems.android.pcctruckerapp" target="_blank" rel="noopener noreferrer"><img src="images/google-play-badge.png" alt="" style={{maxWidth:250 }} className="img-fluid mx-auto" /></a>
                                    </AndroidView>
                                
                                    <IOSView>
                                    <p className="text-muted f-15" style={{ backgroundColor: 'red', borderRadius: 5 }}>
                                        <span style={{color: '#fff'}}>Descarga el app del App Store !</span>
                                    </p>
                                    <a href="https://apps.apple.com/us/app/pcc-trucker-app/id1559797044" rel="noopener noreferrer" target="_blank"><img src="images/app-store.png" alt="" style={{maxWidth:250}} className="img-fluid mx-auto" /></a>
                                    </IOSView>
                                    <BrowserView>

                                        <a href="https://play.google.com/store/apps/details?id=net.cocoasystems.android.pcctruckerapp" target="_blank" rel="noopener noreferrer"><img src="images/google-play-badge.png" alt="" style={{maxWidth:250 }} className="img-fluid mx-auto" /></a>
                                        <a href="https://apps.apple.com/us/app/pcc-trucker-app/id1559797044" rel="noopener noreferrer" target="_blank"><img src="images/app-store.png" alt="" style={{maxWidth:250}} className="img-fluid mx-auto" /></a>

                                    </BrowserView>

                            </div>
                             
                           
                            <p className="text-muted f-14">
                                <b>Patios de Colombia Trucker App</b> es el app
                                que permite al conductor conocer inventario y oferta de reposiciones en cada patio, 
                                actualizar la documentación requerida para cada cita, y conseguir oportunidades de mover contenedores vacíos o con carga local. 
                                <br/>
                                Descarga el app siguiendo en el app para Android o iPhone.

                            </p>

                        </div>
                    </Col>
                    <Col xs="12" md="7" lg="5">
                        <div className="features-img">
                            <img src="images/features-img/trucker_app.png" alt="" style={{maxWidth:200}} className="img-fluid mx-auto d-block" />
                        </div>

                        
                    </Col>
                </Row>
            </div>
        </section>
    )
}