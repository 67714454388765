import React from 'react';
import { Row, Col } from 'reactstrap';
// import { Link } from 'react-router-dom';

class Home1 extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.scrollDuration = Number(this.props.scrollDuration) || 1000;
    }
    easeInOutQuad(current_time, start, change, duration) {
        current_time /= duration / 2;
        if (current_time < 1) return change / 2 * current_time * current_time + start;
        current_time--;
        return -change / 2 * (current_time * (current_time - 2) - 1) + start;
    };

    scrollTo(start, to, duration) {
        let change = to - start,
            currentTime = 0,
            increment = 10;
        let animateScroll = () => {
            currentTime += increment;
            let val = this.easeInOutQuad(currentTime, start, change, duration);
            window.scrollTo(0, val);
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };

        animateScroll();
    }

    render() {

        return (
            <React.Fragment>
                <section className="section home-2-bg" id="home">
                    <div className="home-center">
                        <div className="home-desc-center">
                            <div className="container">
                                <Row className="align-items-center">
                                    <Col lg="5">
                                        <div className="mt-40 home-2-content">
                                            <h2 className="text-white font-weight-normal home-2-title display-5 mb-0">Patios de Contenedores de Colombia</h2>
                                            <p className="text-white-70 mt-4 f-15 mb-0">Nautiservicios Patios de Contenedores de Colombia, una cultura de servicios profesionales puesta a su disposición.</p>
                                            <p className="text-white-70 mt-4 f-15 mb-0">Inspección, almacenaje, manejo, reparación y traslado de contenedores vacíos en Colombia.</p>
                                            <p className="text-white-70 mt-4 f-15 mb-0">LO HACEMOS DIFERENTE</p>
                                            <div style={{ marginTop: 10 }}>
                                                <input type='button'
                                                    onClick={() => {
                                                        // let scrollTargetPosition = document.getElementById('appointemtsLogin').offsetTop - (this.headerBackground ? document.querySelector("div[data-nav='list']").scrollHeight : 0);
                                                        let scrollTargetPosition = document.getElementById('truckerApp').offsetTop - (this.headerBackground ? document.querySelector("div[data-nav='list']").scrollHeight : 30);
                                                        this.scrollTo(window.pageYOffset, scrollTargetPosition, this.scrollDuration);
                                                    }}
                                                    className="submitBnt btn btn-custom"
                                                    value='Trucker App'//'Citas online'
                                                />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col lg="7">
                                        <div className="mt-40 home-2-content position-relative">
                                            <img src="images/home-2-img.png" alt="" className="img-fluid mx-auto d-block home-2-img" />
                                            <div className="home-2-bottom-img">
                                                <img src="images/homr-2-bg-bottom.png" alt="" className="img-fluid d-block mx-auto" />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default Home1;