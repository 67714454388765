import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

class FeatureSection extends React.Component {
    render() {

        return (
            <React.Fragment>
                <section className="section bg-features bg-light" id="features">
                    <div className="container">
                        <Row>
                            <Col className="col-lg-12">
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 font-weight-light text-uppercase">Nuestra Plataforma</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col lg="6">
                                <div className="features-content">
                                    <div className="features-icon">
                                        <i className="pe-7s-science text-primary"></i>
                                    </div>
                                    <h4 className="font-weight-normal text-dark mb-3 mt-4">Información en Tiempo Real</h4>
                                    <p className="text-muted f-14">Apoyados en la última tecnología concebida in-house , pondremos en su teléfono, “en vivo” , todas las actividades relacionadas con nuestros patios.</p>
                                    <p className="text-muted f-14">Nosotros escogimos hacerlo diferente!!!</p>
                                    <p style={{display: 'none'}} className="mb-0 text-uppercase f-13"><Link to="/" className="text-primary">learn more <span className="right-arrow ml-1">&#x21FE;</span></Link></p>
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="features-img mt-32">
                                    <img src="images/features-img/p1.png" alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>

                <section className="section bg-features">
                    <div className="container">
                        <Row className="align-items-center">
                          
                            <Col lg="6">
                                <div className="features-content mt-32">
                                    <div className="features-icon">
                                        <i className="pe-7s-shuffle text-primary"></i>
                                    </div>
                                    <h4 className="font-weight-normal text-dark mb-3 mt-4">Soluciones Estratégicas</h4>
                                    <p className="text-muted f-14">Con este apoyo queremos poner LITERALMENTE en las manos de todos los que intervienen en el proceso, el control y monitoreo de su camión y contenedor.</p>
                                    <p className="text-muted f-14">Importador. Exportador Línea marítima. Empresa de transporte. Camionero.</p>
                                    <p style={{display: 'none'}} className="mb-0 text-uppercase f-13"><Link to="/" className="text-primary">learn more <span className="right-arrow ml-1">&#x21FE;</span></Link></p>
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="features-img">
                                    <img src="images/features-img/p2.png" alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>

            </React.Fragment>
        );
    }
}
export default FeatureSection;