import React from 'react';
import ReactDOM from 'react-dom';
import Index1 from './Index1';
import { Privacy,Cola } from './pages'
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

class Root extends React.Component {

    render() {
        return (
            <BrowserRouter basename={'/'} >
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={Index1} />
                    <Route exact path={`${process.env.PUBLIC_URL}/truckerapp`} component={Index1} />
                    <Route exact path={`${process.env.PUBLIC_URL}/privacy`} component={Privacy} />
                    <Route exact path={`${process.env.PUBLIC_URL}/:patio/cola`} component={Cola}/>
                </Switch>
            </BrowserRouter>
        );
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.unregister();
