import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

class Footer extends React.Component {
    render() {

        return (
            <React.Fragment>
                <section className="footer-bg">
                    <div className="container">
                        <Row>
                            <Col lg="4">
                                <div className="mb-5">
                                    <p className="text-uppercase text-dark footer-title mb-4">nosotros</p>
                                    <p className="text-muted f-14">NAUTISERVICIOS S.A.S. se compromete en ofrecer un servicio de calidad, logrando satisfacer las necesidades de nuestros asociados de negocios basados en la mejora continua del Sistema de Gestión en Control y Seguridad.</p>
                                </div>
                            </Col>

                            <Col lg="8">
                                <Row>
                                    <Col lg="4" >
                                        <div className="mb-5">
                                            <p className="text-uppercase text-dark footer-title mb-4">plataforma</p>
                                            <ul className="list-unstyled footer-sub-menu">
                                                <li className="f-14"><Link to="/" className="text-muted">Transparencia</Link></li>
                                                <li className="f-14"><Link to="/" className="text-muted">Reportes</Link></li>
                                                <li className="f-14"><Link to="/" className="text-muted">App</Link></li>
                                                <li className="f-14"><Link to="/" className="text-muted">Web</Link></li>
                                            </ul>
                                        </div>
                                    </Col>

                                    <Col  lg="4">
                                        <div className="mb-5">
                                            <p className="text-uppercase text-dark footer-title mb-4">soporte</p>
                                            <ul className="list-unstyled footer-sub-menu">
                                                <li className="f-14"><Link to="/" className="text-muted">Solicitudes</Link></li> 
                                                <li className="f-14"><Link to="/" className="text-muted">Preguntas</Link></li>
                                            </ul>
                                        </div>
                                    </Col>

                                    <Col lg="4">
                                        <div className="mb-5">
                                            <p className="text-uppercase text-dark footer-title mb-4">Legal</p>
                                            <ul className="list-unstyled footer-sub-menu">
                                                <li className="f-14"><Link to="/" className="text-muted">Términos y Condiciones</Link></li>
                                                <li className="f-14"><a href="/privacy" className="text-muted">Política de Privacidad</a></li> 
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default Footer;