import React, { useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'reactstrap';
import moment from "moment-timezone";
import momentLocale from "moment";
import {
    Refresh,
    InsertDriveFile as Document,
    Smartphone
} from '@material-ui/icons'
import {
    Button,
    IconButton,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    DialogContentText
} from '@material-ui/core'
import 'moment/locale/es'
import IcoMoon from "react-icomoon";

moment.locale('es')
const iconSet = require("../fonts/config.json");


const Icon = ({ ...props }) => {
    return <IcoMoon iconSet={iconSet} {...props} />;
};

const PATIOS = [
    {
        id: 1,
        code: 'BOG',
        name: 'Bogotá'
    },
    {
        id: 2,
        code: 'BUN',
        name: 'Buenaventura'
    },
    {
        id: 3,
        code: 'FKE',
        name: 'PRUEBAS'
    },
    {
        id: 4,
        code: 'CLO',
        name: 'Cali'
    },
    {
        id: 5,
        code: 'MDE',
        name: 'Medellín'
    },

];
const utcOffset = -5;



const fnStatus = (d) => {
    /*en el app:
        if (d.requiredDocumentMissing > 0) return "Esperando Docs del Camionero"
    
        if (d.documentationApproved === null && d.operationType === "IMPO" && d.inspeccionado == null) return "Esperando Revisión Docs/ Inspección"
    
        if (d.documentationApproved === null) return "Esperando\nRevisión Docs"
        console.log(d.inspeccionado ,"<<<<")
        if (d.operationType === "IMPO" && d.inspeccionado == null) return "Esperando Inspección"
    
        if (d.autorizadoOperaciones != null && d.statusId === 2) return "Esperando Conductor"
        if (d.operacionRealizada == null && d.statusId === 3) return (d.operationType === "IMPO" ? "Esperando\nDescargue" : "Esperando\nCargue")
    
        if (d.enCola == null && d.statusId === 2 && d.documentationApproved != null) return "Esperando\nAutorización Cola"
    
        if (d.documentationApproved != null && d.statusId === 2
    
            && (
                (d.operationType === "IMPO" && d.inspeccionado == null)
                ||
                d.operationType === "EXPO"
            )
    
        ) return "Esperando\nAutorización Patio"
    
        if (d.autorizadoOperaciones == null) return "Esperando Autorización Operaciones"
    
        return JSON.stringify(d)*/
    if (d.requiredDocumentMissing > 0) return "Esperando Docs del Camionero"

    if (d.documentationApproved === null && d.operationType === "IMPO" && d.inspeccionado === null) return "Esperando Revisión Docs/ Inspección"

    if (d.operationType === "IMPO" && d.inspeccionado == null) return "Esperando Inspección"

    if (d.autorizadoOperaciones != null && d.statusId === 2) return "Esperando Conductor"

    if (d.operacionRealizada == null && d.statusId === 3) return (d.operationType === "IMPO" ? "Esperando\nDescargue" : "Esperando\nCargue")

    if (d.enCola == null && d.statusId === 2 && d.documentationApproved != null) return "Esperando\nAutorización Cola"

    if (d.documentationApproved != null && d.statusId === 2
        && (d.operationType === "IMPO" && d.inspeccionado == null)
    ) return "Esperando\nAutorización Patio"

    if (d.autorizadoOperaciones == null) return "Esperando Autorización Operaciones"

    return JSON.stringify(d)
}



const PccIcon = (props) => {
    switch (props.name) {
        case 'truck':
            return <Icon icon="truck" size={20} color={props.style.color} />;
        case 'container':
            return <Icon icon="container" size={20} color={props.style.color} />;
        case 'forklift':
            return <Icon icon="forklift" size={20} color={props.style.color} />;
        default:
            return null;
    }

}

const IconCola = ({ d }) => {

    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    }}>
        {d.operationType === "IMPO" &&
            d.inspeccionDamage == null &&
            <PccIcon name="container" style={{
                fontSize: 20,
                color: "red",
                marginRight: 10
            }} />

        }
        {d.documentationApproved == null &&
            <Document name="ios-document" style={{ fontSize: 20, color: 'red' }} />
        }

        {d.statusId === 3 &&
            <PccIcon name="forklift" style={{ fontSize: 25, color: 'red' }} />
        }
        {d.autorizadoOperaciones != null && d.statusId === 2 &&
            <PccIcon name="truck" style={{ fontSize: 30, color: 'green' }} />
        }
        {d.autorizadoOperaciones == null && d.statusId === 2 && d.documentationApproved != null
            &&
            (
                (d.operationType === "IMPO" && d.inspeccionDamage != null)
                ||
                d.operationType === "EXPO"
            )

            &&

            <PccIcon name="truck" style={{ fontSize: 30, color: 'red' }} />
        }
    </div>

}




export default function Cola(props) {
    const [DATA, setDATA] = useState([]);
    const patio = PATIOS.find(p => p.code === props.match.params.patio.toUpperCase());
    const [sendingNotification, setSendingNotification] = useState([]);
    const [inspeccion, setInspeccion] = useState([])
    const [fetching, setFetching] = useState(false);
    const [lastUpdate, setLastUpdate] = useState(moment());
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);




    const fetchData = useCallback(
        () => {
            //cloud9-TruckerAppCitas-ColaStatus-1N9VHL4MK2O07
            // SQL kpi_camionesEnElPatio
            setFetching(true);
            fetch(`https://wy1hk2dss5.execute-api.us-east-1.amazonaws.com/v1/truckerApp/yard/${patio.id}/cola/`)
                .then(res => {
                    return res.json()
                })
                .then(data => {
                    setLastUpdate(moment());
                    setFetching(false)
                    if (data.success) {
                        setDATA(data.cola.filter(row => row.operationType === 'IMPO' && (row.inspeccionado == null || (row.enCola == null && row.statusId === 2 && row.documentationApproved != null))))
                    }
                })
        },
        [patio.id],
    )

    useEffect(() => {
        fetchData();
    }, [fetchData])


    useEffect(() => {
        const id = setInterval(() => {
            if (!showConfirmation) {
                fetchData();
            }
        }, 60000);
        return () => clearInterval(id);
    }, [fetchData, showConfirmation]);


    const fnSendNotification = (d) => {
        //Cloud9: cloud9-TruckerAppPushNoti-InspeccionNotificarConductor
        //SQL: InspectorNotificarConductorTurnoGet
        setShowConfirmation(false);
        setSelectedRow(null);
        setSendingNotification([...sendingNotification, d.codigoTurno]);
        fetch(`https://wy1hk2dss5.execute-api.us-east-1.amazonaws.com/v1/notificar/${d.codigoTurno}/inspeccion/`)
            .then(res => {
                return res.json()
            })
            .then(data => {
                setSendingNotification(sendingNotification.filter(n => n !== d.codigoTurno));
                console.log(data)
                if (data.success) {
                    setDATA(DATA.map(dt => dt.codigoTurno === d.codigoTurno ? { ...dt, notificacionesEnviadasInspeccionUltimaDateTime: moment().utcOffset(utcOffset).format() } : dt))
                    alert("Conductor notificado");
                } else {
                    alert(data.userMessage);
                    console.log(data);
                }
            })
    }

    const fnComenzarInspeccion = (d) => {
        //Cloud9: cloud9-TruckerAppCitas-CitaComenzarInspeccion
        //SQL: CitaComenzarInspeccion
        setInspeccion([...sendingNotification, d.id]);
        console.log(`https://wy1hk2dss5.execute-api.us-east-1.amazonaws.com/v1/truckerApp/citas/${d.id}/inspeccion/`)
        fetch(`https://wy1hk2dss5.execute-api.us-east-1.amazonaws.com/v1/truckerApp/citas/${d.id}/inspeccion/`, {
            method: 'POST'
        })
            .then(res => {
                return res.json()
            })
            .then(data => {
                setInspeccion(inspeccion.filter(n => n !== d.id));
                if (data.success) {
                    setDATA(DATA.map(dt => dt.codigoTurno === d.codigoTurno ? { ...dt, ...data.turno } : dt))
                    alert("Inspeccion Comenzada");
                } else {
                    alert(data.userMessage);
                    console.log(data);
                }
            }).catch(e => {
                console.log(e)
            })
    }
    const fnCancelarInspeccion = (d) => {
        //Cloud9: cloud9-TruckerAppCitas-CitaCancelarInspeccion
        //SQL: CitaCace;arInspeccion
        setInspeccion([...sendingNotification, d.id]);
        fetch(`https://wy1hk2dss5.execute-api.us-east-1.amazonaws.com/v1/truckerApp/citas/${d.id}/inspeccion/`, {
            method: 'DELETE'
        })
            .then(res => {
                return res.json()
            })
            .then(data => {
                setInspeccion(inspeccion.filter(n => n !== d.id));
                if (data.success) {
                    setDATA(DATA.map(dt => dt.codigoTurno === d.codigoTurno ? { ...dt, ...data.turno } : dt))
                    alert("Inspección cancelada");
                } else {
                    alert(data.userMessage);
                    console.log(data);
                }
            })
    }

    const BotonNotificarInspeccion = ({ operation }) => {
        return <>
            {operation.operationType === "IMPO" && operation.inspeccionado == null &&

                <div style={{
                    alignItems: 'flex-end',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <Button
                        disabled={sendingNotification.find(n => n === operation.codigoTurno) ? true : false}
                        onClick={() => {
                            setSelectedRow(operation);
                            setShowConfirmation(true);
                        }}
                        style={{
                            padding: 5,
                            height: 32,
                            marginLeft: 10,
                            backgroundColor: operation.notificacionesEnviadasInspeccionUltimaDateTime ? 'green' : '#CF4046'
                        }}>
                        {sendingNotification.find(n => n === operation.codigoTurno) ?
                            <CircularProgress size={20} />
                            :
                            <span style={{ fontSize: 10, color: '#FFF' }}>Notificar</span>
                        }
                    </Button>
                    {operation.notificacionesEnviadasInspeccionUltimaDateTime &&
                        <div style={{ marginTop: 5, fontSize: 12, textAlign: 'right' }}>Notificado {momentLocale(operation.notificacionesEnviadasInspeccionUltimaDateTime).utcOffset(utcOffset).from(momentLocale().utcOffset(utcOffset))}</div>}
                </div>
            }
            {
                operation.inspeccionado &&
                <span style={{ fontSize: 12, textAlign: 'right', }}>Inspeccionado</span>
            }
        </>

    }


    if (!patio) return null;

    return (
        <div>
            <Helmet>
                <title>Patios de Contenedores de Colombia | Cola de Camioneros Esperando Inspección</title>
            </Helmet>
            <nav id="main_navbar"
                className="navbar navbar-expand-lg  fixed-top  sticky sticky-dark"
                style={{
                    background: '#D04046'
                }}>
                <div className="container">
                    <div style={{ flex: 1 }}>
                        <Link className="navbar-brand logo" to="/" >
                            <img src="/images/pcc_logo_whitebg.png" alt="Patio de Contenedores de Colombia Logo" height="20" />
                        </Link>
                    </div>
                    <div style={{
                        flex: 4,
                        color: '#FFF',
                        textAlign: 'center'
                    }}>
                        <h5>{patio.name}</h5>
                        <span style={{ fontSize: 14 }}>Esperando Inspección</span>
                    </div>
                    <div style={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <IconButton size='small'
                            onClick={() => {
                                fetchData();
                            }}>
                            <Refresh style={{ color: '#fff' }} />
                        </IconButton>
                    </div>
                </div>
            </nav>
            <section className="section" >
                <div className="home-center">
                    <div className="home-desc-center">
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            {fetching ?
                                <div style={{
                                    flex: 1,
                                    alignItems: "center",
                                    paddingTop: 50
                                }}>
                                    <CircularProgress />
                                </div> :
                                <>

                                    {DATA.length === 0
                                        ?
                                        <div style={{
                                            flex: 1,
                                            alignItems: "center",
                                            paddingTop: 50
                                        }}>

                                            <span>No hay camiones de IMPO esperando inspección</span>
                                        </div>
                                        :
                                        <>
                                            <Container >
                                                {DATA.map((d, ix) => (
                                                    <React.Fragment key={ix}>
                                                        <Row
                                                            style={{
                                                                flex: 1,
                                                                paddingTop: 5,
                                                                paddingBottom: 5,
                                                                backgroundColor: ix % 2 === 0 ? '#e0e0e0' : "#fff",

                                                            }}
                                                            xs='auto'>
                                                            <Col
                                                                style={{
                                                                    minWidth: 20,
                                                                    flex: 2
                                                                }}>
                                                                <div style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    alignItems: 'flex-start'
                                                                }}>
                                                                    <div style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        backgroundColor: '#c0c0c0',
                                                                        marginRight: 10,
                                                                        borderRadius: 5,
                                                                        padding: 5
                                                                    }}>
                                                                        <span style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>{ix + 1}</span>
                                                                    </div>
                                                                    <div style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                    }}>
                                                                        <span style={{
                                                                            fontSize: 14,
                                                                            fontWeight: 'bold'
                                                                        }}>{d.firstName} {d.lastName}</span>
                                                                        <span style={{
                                                                            fontSize: 14,
                                                                            color: 'blue',
                                                                            fontWeight: 'bold'
                                                                        }}>{d.placa}</span>
                                                                        <span style={{
                                                                            fontSize: 14,
                                                                            color: 'blue',
                                                                            fontWeight: 'bold'
                                                                        }}>{d.codigoTurno}</span>
                                                                        <span style={{
                                                                            fontSize: 14,
                                                                        }}>{d.cType}{d.cSize}</span>
                                                                        <span style={{
                                                                            fontSize: 14,
                                                                            color: 'blue',
                                                                            flexWrap: 'nowrap'
                                                                        }}>{moment(d.fechaHoraCita).utcOffset(utcOffset).format("HH:mm")}</span>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col style={{
                                                                minWidth: 20,
                                                                flex: 1,
                                                                display: 'flex',
                                                                flexDirection: 'column'
                                                            }}>
                                                                <span style={{ textAlign: 'center', fontWeight: 'bold', marginLeft: 5 }}>{d.minutosEnElPatio}'</span>
                                                                <span style={{ textAlign: 'center' }}>{moment(d.llegadaAlPatio).utcOffset(utcOffset).format("HH:mm")}</span>
                                                                {d.documentationApproved &&
                                                                    <div style={{ flexDirection: 'row', display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                                                                        <Document style={{ fontSize: 15, color: 'green', marginRight: 3 }} />
                                                                        <span style={{ color: 'green', fontSize: 14, textAlign: 'center' }} >{moment(d.documentationApproved).utcOffset(utcOffset).format("HH:mm")}</span>
                                                                    </div>
                                                                }
                                                            </Col>
                                                            <Col style={{
                                                                minWidth: 20,
                                                                flex: 1,
                                                                display: 'flex',
                                                                alignItems: 'flex-end',
                                                                flexDirection: 'column'
                                                            }}>
                                                                {d.lastLoginDateTime &&
                                                                    <Smartphone style={{ fontSize: 30, color: 'green' }} />
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row xs='12' style={{
                                                            flex: 1,
                                                            padding: 5,
                                                            backgroundColor: ix % 2 === 0 ? '#e0e0e0' : "#fff",
                                                        }}>

                                                            <Col xs={12} >
                                                                <div style={{
                                                                    display: 'flex',
                                                                    width: '100%',
                                                                    flexDirection: 'row',
                                                                    alignItems: 'flex-start',
                                                                    justifyContent: 'space-between',
                                                                }}>
                                                                    <div style={{
                                                                        flexDirection: 'row',
                                                                        backgroundColor: '#f8f8f8',
                                                                        padding: 5,
                                                                        borderRadius: 5,
                                                                        borderColor: '#808080',
                                                                        borderStyle: 'solid',
                                                                        borderWidth: 2,
                                                                        width: 170,
                                                                        display: 'flex'
                                                                    }}>
                                                                        <IconCola
                                                                            d={d}
                                                                        />
                                                                        <span style={{ flex: 1, fontSize: 12, textAlign: 'center', marginLeft: 5 }} >{fnStatus(d)}</span>
                                                                    </div>
                                                                    <div style={{
                                                                        alignItems: 'flex-end',
                                                                        display: 'flex',
                                                                        flexDirection: 'column'
                                                                    }}>
                                                                        {!d.inspeccionComenzada ?
                                                                            <>
                                                                                <BotonNotificarInspeccion operation={d} />
                                                                                {!d.inspeccionado && (

                                                                                    <Button
                                                                                        color='primary'
                                                                                        variant='contained'
                                                                                        style={{
                                                                                            marginTop: 10
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            fnComenzarInspeccion(d);
                                                                                        }}>
                                                                                        {inspeccion.find(n => n === d.id) ?
                                                                                            <CircularProgress size={20} color='#FFF' />
                                                                                            :
                                                                                            <span style={{ fontSize: 10, color: '#FFF' }}>Comenzar Inspección</span>
                                                                                        }
                                                                                    </Button>
                                                                                )}
                                                                            </>
                                                                            : <>
                                                                                <Button
                                                                                    variant='contained'
                                                                                    style={{
                                                                                        padding: 5,
                                                                                        height: 32,
                                                                                        marginLeft: 10,
                                                                                        backgroundColor: inspeccion.find(n => n === d.id) ? '#CF4046' : undefined,

                                                                                    }}
                                                                                    onClick={() => {
                                                                                        fnCancelarInspeccion(d);
                                                                                    }}>
                                                                                    {inspeccion.find(n => n === d.id) ?
                                                                                        <CircularProgress size={20} color='#FFF' />
                                                                                        :
                                                                                        <span style={{ fontSize: 10, color: '#000', }}>Deshacer Inicio Inspección</span>
                                                                                    }
                                                                                </Button>
                                                                                <span style={{ fontSize: 12, textAlign: 'right', marginTop: 10 }}>Inspección Comenzada</span>
                                                                            </>}
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        {d.lastNoteTime != null &&
                                                            <Row xs='12' style={{
                                                                flex: 1,
                                                                padding: 5,
                                                                backgroundColor: ix % 2 === 0 ? '#e0e0e0' : "#fff",
                                                            }}>
                                                                <Col>
                                                                    <span style={{ color: '#000', fontSize: 12, textAlign: 'left', width: '100%' }}>
                                                                        [{`${moment(d.lastNoteTime).utcOffset(utcOffset).format("HH:mm")}`}] {d.lastNoteContent}
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </React.Fragment>
                                                ))}
                                            </Container>
                                        </>}
                                </>}
                        </div>
                    </div>
                </div>
            </section>
            <div style={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: '#fff'
            }}>
                Última actualización {moment(lastUpdate).format('hh:mm A')}
            </div>

            <Dialog
                open={showConfirmation}
                onClose={() => {
                    setShowConfirmation(false);
                    setSelectedRow(null);
                }}
            >
                <DialogTitle>Confirmación</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {selectedRow &&
                            <>
                                {selectedRow.firstName} {selectedRow.lastName} {selectedRow.placa}
                                <br />
                                {selectedRow.notificacionesEnviadasInspeccionUltimaDateTime ? `Notificado ${momentLocale(selectedRow.notificacionesEnviadasInspeccionUltimaDateTime).utcOffset(utcOffset).from(momentLocale().utcOffset(utcOffset))}` : 'No ha sido notificado'}
                                <br />
                            </>}

                        Enviar notificacion de inspección?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setShowConfirmation(false);
                            setSelectedRow(null);
                        }}>
                        No
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            fnSendNotification(selectedRow);
                        }}>
                        Sí
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
