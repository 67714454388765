import React from 'react';
import Preloader from './components/Preloader';
import Navbar from './components/Navbar';
import Home1 from './components/Home1';
import AboutSection from './components/AboutSection';
import ServiceSection from './components/ServiceSection';
import FeatureSection from './components/FeatureSection';
import ClientSection from './components/ClientSection';
//import PricingSection from './components/PricingSection';
//import BlogSection from './components/BlogSection';
import ContactSection from './components/ContactSection';
import Footer from './components/Footer';
import FooterAlt from './components/FooterAlt';

// import { LoginSection } from 'components/Login'
import { TruckerAppSection } from 'components/TruckerApp'

class Index1 extends React.Component {

  constructor(props) {
    super(props);
    this.props = props;
    this.scrollDuration = Number(this.props.scrollDuration) || 1000;
  }

  easeInOutQuad(current_time, start, change, duration) {
    current_time /= duration / 2;
    if (current_time < 1) return change / 2 * current_time * current_time + start;
    current_time--;
    return -change / 2 * (current_time * (current_time - 2) - 1) + start;
  };

  scrollTo(start, to, duration) {
    let change = to - start,
      currentTime = 0,
      increment = 10;
    let animateScroll = () => {
      currentTime += increment;
      let val = this.easeInOutQuad(currentTime, start, change, duration);
      window.scrollTo(0, val);
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };

    animateScroll();
  }

  componentDidMount() {
    document.getElementById("main_navbar").classList.add("navbar-light");
    if (this.props.location.pathname.toLowerCase() === '/truckerapp') {
      setTimeout(() => {
        let scrollTargetPosition = document.getElementById('truckerApp').offsetTop - (this.headerBackground ? document.querySelector("div[data-nav='list']").scrollHeight : 30);
        this.scrollTo(window.pageYOffset, scrollTargetPosition, this.scrollDuration);
      }, 1500)
    }

  }

  render() {

    return (
      <React.Fragment>

        {/* preloader */}
        <Preloader />

        {/* Navigation Menu */}
        <Navbar />

        {/* HomeSection Menu */}
        <Home1 />

        <TruckerAppSection />
     
        <AboutSection />

        {/* ServiceSection Menu */}
        <ServiceSection />

        {/* FeatureSection Menu */}
        <FeatureSection />


        {/* ClientSection Menu */}
        <ClientSection />

        {/* Login section */}
        {/* <LoginSection /> */}


        {/* ContactSection Menu */}
        <ContactSection />

        {/* Footer Menu */}
        <Footer />

        {/* FooterAlt Menu */}
        <FooterAlt />

      </React.Fragment>

    );
  }
}

export default Index1;