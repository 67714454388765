import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

class AboutSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            AboutList: [{
                "icon": "mdi mdi-nature",
                "title": "Bogotá",
                "content": "Km. 2.5 Vía Variante Madrid Facatativá",
            }, {
                "icon": "mdi mdi-nature",
                "title": "Cali",
                "content": "Carrera 34 entre Calle 16 y 17 Zona Industrial ACOPI",
            }, {
                "icon": "mdi mdi-nature",
                "title": "Buenaventura",
                "content": "Avenida portuaria – Frente al comando antinarcóticos",
            },{
                "icon": "mdi mdi-nature",
                "title": "Medellín",
                "content": "Carrera 45 Nro 134Sur\n 619 kilometro 12",
            },
            ]
        };
    }

    render() {

        return (
            <React.Fragment>
                <section className="section bg-about bg-light-about bg-light" id="about">
                    <div className="container">
                        <Row>
                            <Col lg="4">
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 font-weight-light text-uppercase">Sobre Nosotros</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            {this.state.AboutList.map((about, index) => {
                                return <Col lg="3" key={index}>
                                            <div className="about-box about-light text-center p-3">
                                                <div className="about-icon mb-4">
                                                    <i className={about.icon}></i>
                                                </div>
                                                <h4 className="font-weight-light"><Link to="#" className="text-dark">{about.title}</Link></h4>
                                                <p className="text-muted f-14">{about.content}</p>
                                            </div>
                                        </Col>
                              })}
                         </Row>

                        <Row className="align-items-center mt-5">
                            <Col md="6">
                                <div className="about-desc">
                                    <h3 className="text-dark mb-3 font-weight-light">NAUTISERVICIOS S.A.S.</h3>
                                    <p className="text-muted f-15">Ofrece los servicios de almacenaje, mantenimiento y reparación de todo tipo de contenedores basados en altos estándares de calidad para nuestros asociados de contando con un excelente equipo humano en nuestra organización.</p>
                                    <div className="about-by" style={{display: 'none'}}>
                                        <p className="font-weight-light mb-0"><Link to="#" className="text-dark"><i className="mdi mdi-circle-medium text-custom mr-2"></i>
                                            Más <span className="text-custom"> sobre Nosotros</span></Link></p>
                                    </div>
                                </div>
                            </Col>
                        
                            <Col md="6">
                                <div className="about-img light-img position-relative p-4">
                                    <img src="images/about-img.jpg" alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default AboutSection;